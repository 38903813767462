import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import { Box, Container, TextSM, Button, Flex } from "../../ui"
import { setCookie, getCookie } from "../../utils/cookie"
import { useTranslation } from "../../utils/i18n"

const COOKIE_NAME = "CookieConsent"

export const CookieConsent = ({
  style,
  background,
  containerStyle,
  contentStyle,
  buttonStyle,
}) => {
  const { t } = useTranslation()
  const [isAccepted, setIsAccepted] = useState()

  useEffect(() => setIsAccepted(getCookie(COOKIE_NAME)), [])

  if (isAccepted) return null

  const handleClick = () => {
    setCookie(COOKIE_NAME, true, 365)
    setIsAccepted(true)
  }

  return (
    <Box
      bg={background}
      color="white"
      position="fixed"
      bottom="0"
      width="100%"
      zIndex={1000}
      py="md"
      alignItems="center"
      {...style}
    >
      <Container maxW={"container.sm"} {...containerStyle}>
        <Flex
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
          {...contentStyle}
        >
          <Box flex="1 0 259px">
            <TextSM>{t("cookie_message")}</TextSM>
          </Box>
          <Button
            onClick={handleClick}
            colorScheme="primary"
            ml={[0, "md"]}
            mt={["md", 0]}
            {...buttonStyle}
          >
            {t("cookie_button")}
          </Button>
        </Flex>
      </Container>
    </Box>
  )
}

CookieConsent.propTypes = {
  style: PropTypes.object,
  background: PropTypes.string,
  containerStyle: PropTypes.object,
  contentStyle: PropTypes.object,
  buttonStyle: PropTypes.object,
}

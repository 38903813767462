import React, { useRef } from "react"
import PropTypes from "prop-types"

import {
  IconButton,
  MenuIcon,
  Modal,
  ModalContent,
  useDisclosure,
  XCloseIcon,
  Flex,
  Divider,
} from "../../ui"

export const MobileSiteNav = ({ header, children }) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const finalFocusRef = useRef()
  const initialFocusRef = useRef()

  const Header = header
  const Content = children

  return (
    <>
      <IconButton
        ref={finalFocusRef}
        variant="ghost"
        onClick={onOpen}
        icon={<MenuIcon />}
        isRound
        _focus={{ outline: 0 }}
      />

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        finalFocusRef={finalFocusRef}
        initialFocusRef={initialFocusRef}
        motionPreset="none"
        size="full"
      >
        <ModalContent borderRadius={0}>
          <Flex alignItems="center" justifyContent="space-between" p="sm">
            <Header onClose={onClose} />
            <IconButton
              ref={initialFocusRef}
              variant="ghost"
              icon={<XCloseIcon />}
              isRound
              onClick={onClose}
              _focus={{ outline: 0 }}
            />
          </Flex>
          <Divider />
          <Content onClose={onClose} />
        </ModalContent>
      </Modal>
    </>
  )
}

MobileSiteNav.propTypes = {
  header: PropTypes.func,
  children: PropTypes.func,
}

const shadows = {
  sm: "0 0.25rem 0.5rem rgba(62,50,87,0.2)",
  md: "0 0.5rem 1rem rgba(62,50,87,0.2)",
  lg: "0 0.75rem 1.5rem rgba(62,50,87,0.2)",
  outline: "0 0 0 0.25rem rgba(62,50,87,0.2)",
  inner: "inset 0 2px 4px 0 rgba(0,0,0,0.06)",
  "dark-lg":
    "rgba(0, 0, 0, 0.1) 0px 0px 0px 1px, rgba(0, 0, 0, 0.2) 0px 5px 10px, rgba(0, 0, 0, 0.4) 0px 15px 40px",
  none: "none",
}

export default shadows

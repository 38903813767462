import { css } from "@emotion/react"

export const globalStyles = css`
  address {
    font-style: normal;
  }
  ::-moz-selection {
    background-color: #8f008a;
    color: white;
  }
  ::selection {
    background-color: #8f008a;
    color: white;
  }
  option {
    color: initial;
  }
  a[type="button"] {
    text-decoration: none;
  }
  a[type="button"]:hover {
    text-decoration: none;
  }
  a[type="button"]:focus {
    text-decoration: none;
  }

  code {
    font-size: 1rem;
    padding: 0 3px;
    background: #eee;
    border-radius: 2px;
  }
  *:focus {
    box-shadow: none !important;
  }
  #nprogress {
    pointer-events: none;
  }
  #nprogress .bar {
    background: #511ee9;
    position: fixed;
    z-index: 1031;
    top: 0;
    left: 0;
    width: 100%;
    height: 0.25rem;
  }
`

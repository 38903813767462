import localFont from "@next/font/local"
import { Source_Sans_3 } from "@next/font/google"

const averta = localFont({
  src: [
    {
      path: "../../public/static/fonts/averta-regular.woff2",
      weight: "400",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/averta-semibold.woff2",
      weight: "600",
      style: "normal",
    },
    {
      path: "../../public/static/fonts/averta-bold.woff2",
      weight: "700",
      style: "normal",
    },
  ],
})

const sourceSans = Source_Sans_3({ subsets: ["latin"] })

const typography = {
  letterSpacings: {
    tightest: "-0.0075em",
    tighter: "-0.005em",
    tight: "-0.0025em",
    normal: "0",
    wide: "0.0025em",
    wider: "0.005em",
    widest: "0.0075em",
  },

  lineHeights: {
    none: "1",
    shorter: "calc(1em + 0.25rem)",
    short: "calc(1em + 0.25rem)",
    base: "calc(1em + 0.5rem)",
    tall: "calc(1em + 0.75rem)",
    taller: "calc(1em + 0.75rem)",

    dense: "calc(1em + 0.25rem)",
    normal: "calc(1em + 0.5rem)",
    loose: "calc(1em + 0.75rem)",
  },

  fontWeights: {
    hairline: 100,
    thin: 200,
    light: 300,
    normal: 400,
    medium: 500,
    semibold: 600,
    bold: 700,
    extrabold: 800,
    black: 900,
  },

  fonts: {
    body: sourceSans.style.fontFamily,
    heading: averta.style.fontFamily,
    mono: "IBM Plex Mono, Menlo, monospace",
  },

  fontSizes: {
    "3xs": "0.25rem",
    "2xs": "0.5rem",
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    "2xl": "2rem",
    "3xl": "2.5rem",
    "4xl": "3rem",
    "5xl": "5rem",
    /*  "6xl": "3.75rem",
    "7xl": "4.5rem",
    "8xl": "6rem",
    "9xl": "8rem", */
  },
}
export default typography

import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useRouter } from "next/router"

import { PageContext } from "../../utils/page"
import { useTranslation } from "../../utils/i18n"
import {
  TextSM,
  Link,
  IconButton,
  Box,
  TwitterIcon,
  FacebookIcon,
  InstagramIcon,
  GithubIcon,
  MailIcon,
} from "../../ui"

const twitterShareUrl = (rootUrl, referer) => {
  const twitterRoot = "http://twitter.com/intent/tweet"
  const fullReferer = encodeURIComponent(rootUrl + referer)
  return `${twitterRoot}?url=${fullReferer}&&original_referer=${fullReferer}`
}

const facebookShareUrl = (rootUrl, referer) => {
  const facebookRoot = "http://facebook.com/sharer/sharer.php"
  const fullReferer = encodeURIComponent(rootUrl + referer)
  return `${facebookRoot}?u=${fullReferer}`
}

const emailShareUrl = (rootUrl, referer, emailSubject) =>
  `mailto:?subject=${encodeURIComponent(
    emailSubject
  )}&body=${encodeURIComponent(rootUrl + referer)}`

const icons = socialMediaIds => ({
  facebook: {
    alt: "facebook",
    linkUrl: `https://www.facebook.com/${socialMediaIds.facebook}`,
    translation: "visit-facebook",
    color: "facebook.500",
  },
  twitter: {
    alt: "twitter",
    linkUrl: `https://twitter.com/${socialMediaIds.twitter}`,
    translation: "visit-twitter",
    color: "twitter.500",
  },
  instagram: {
    alt: "instagram",
    linkUrl: `http://instagram.com/${socialMediaIds.instagram}`,
    translation: "visit-instagram",
    color: "instagram.500",
  },
  github: {
    alt: "github",
    linkUrl: `https://github.com/${socialMediaIds.github}`,
    translation: "visit-github",
    color: "github.500",
  },
  email: {
    alt: "email",
    linkUrl: `mailto:${socialMediaIds.email}`,
    translation: "email",
    color: "gray.500",
  },
})

const getIcon = (type, rest) => {
  switch (type) {
    case "facebook":
      return <FacebookIcon {...rest} />
    case "twitter":
      return <TwitterIcon {...rest} />
    case "email":
      return <MailIcon {...rest} />
    case "github":
      return <GithubIcon {...rest} />
    case "instagram":
      return <InstagramIcon {...rest} />
  }
}

export const SocialMediaItem = ({
  type,
  iconSize = "2rem",
  disableText = false,
  isIconButton = false,
  isFollowUs = false,
  iconColor,
}) => {
  const { t } = useTranslation()
  const { asPath } = useRouter()
  const { socialMedia } = useContext(PageContext)
  const { rootUrl, socialMediaIds, emailSubject } = socialMedia

  const iconProperties = icons(socialMediaIds)[type]
  let url = iconProperties.linkUrl

  if (!isFollowUs) {
    if (type === "facebook") url = facebookShareUrl(rootUrl, asPath)
    if (type === "twitter") url = twitterShareUrl(rootUrl, asPath)
    if (type === "email") url = emailShareUrl(rootUrl, asPath, emailSubject)
  }

  return (
    <Link textAlign="center" href={url} isExternal variant="standalone">
      {isIconButton ? (
        <IconButton
          bg="whiteAlpha.200"
          icon={getIcon(type)}
          alt={iconProperties.alt}
        />
      ) : (
        getIcon(type, {
          boxSize: iconSize,
          fill: "white",
          color: iconColor || iconProperties.color,
        })
      )}
      {!disableText && (
        <Box
          textAlign="center"
          maxWidth={["7rem", "auto", "auto", "auto"]}
          m="auto"
        >
          <TextSM mt="md">
            {t(`og_c:social-media_${iconProperties.translation}`)}
          </TextSM>
        </Box>
      )}
    </Link>
  )
}

SocialMediaItem.propTypes = {
  type: PropTypes.oneOf([
    "facebook",
    "twitter",
    "instagram",
    "github",
    "email",
  ]),
  disableText: PropTypes.bool,
  iconSize: PropTypes.string,
  iconColor: PropTypes.string,
  isDefaultUrl: PropTypes.bool,
  isIconButton: PropTypes.bool,
  isFollowUs: PropTypes.bool,
}

import React from "react"
import PropTypes from "prop-types"
import {
  Button as CHButton,
  ButtonGroup as CHButtonGroup,
  IconButton as CHIconButton,
} from "@chakra-ui/react"

import { forwardRef, useTheme, useRadio } from "../Hooks"

export const IconButton = forwardRef(
  (
    {
      icon,
      size = "md",
      variant = "solid",
      isRound,
      borderRadius,
      ...restProps
    },
    ref
  ) => {
    const iconSizes = {
      sm: "1rem",
      md: "1.125rem",
      lg: "1.375rem",
    }

    return (
      <CHIconButton
        ref={ref}
        size={size}
        p="0"
        borderRadius={borderRadius || isRound ? "100%" : "md"}
        icon={React.cloneElement(icon, { boxSize: iconSizes[size] })}
        variant={variant}
        {...restProps}
      />
    )
  }
)

IconButton.propTypes = {
  icon: PropTypes.element,
  size: PropTypes.oneOf(["xs", "sm", "md", "lg"]),
  isRound: PropTypes.bool,
  variant: PropTypes.oneOf(["solid", "outline", "ghost", "link", "unstyled"]),
  borderRadius: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
}

export const Button = forwardRef(({ size = "md", ...restProps }, ref) => {
  const { buttonPaddings } = useTheme()

  return (
    <CHButton
      ref={ref}
      px={buttonPaddings[size]}
      lineHeight="dense"
      size={size}
      {...restProps}
    />
  )
})

Button.propTypes = {
  size: PropTypes.string,
  colorScheme: PropTypes.string,
}

export const ButtonGroupButton = forwardRef((props, ref) => {
  const {
    isChecked,
    isDisabled,
    activeVariant = "blurble",
    inactiveVariant = "gray",
    children,
    ...rest
  } = props

  const { getInputProps, getCheckboxProps } = useRadio({
    ...props,
    isChecked,
    isDisabled,
  })

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Button
      ref={ref}
      {...checkbox}
      as="label"
      justifyContent="center"
      alignItems="center"
      display="inline-flex"
      variant="solid"
      colorScheme={isChecked ? activeVariant : inactiveVariant}
      aria-checked={isChecked}
      role="radio"
      isDisabled={isDisabled}
      zIndex={isChecked ? 2 : 1}
      lineHeight="dense"
      {...rest}
    >
      <input {...input} />
      {children}
    </Button>
  )
})

ButtonGroupButton.propTypes = {
  isChecked: PropTypes.bool,
  isDisabled: PropTypes.bool,
  activeVariant: PropTypes.string,
  inactiveVariant: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
}

export const ButtonGroup = forwardRef(
  (
    { width, isDistributedEvenly, children, variant = "solid", ...restProps },
    ref
  ) => {
    const css = {
      "> *": width === "full" ? { flex: "1 1 auto" } : {},
      "> *:not(:first-of-type):not(:last-of-type)": {
        borderRadius: "0",
      },
      "> *:first-of-type": {
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0",
      },
      "> *:last-of-type": {
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
      },
      gridTemplateColumns: `repeat(${children.length}, 1fr)`,
      spacing: variant === "outline" ? "-0.125rem" : "0.125rem",
    }
    if (isDistributedEvenly) css.display = "grid"
    return (
      <CHButtonGroup
        ref={ref}
        defaultValue="rad2"
        spacing="0"
        width={width}
        css={css}
        {...restProps}
      >
        {children}
      </CHButtonGroup>
    )
  }
)

ButtonGroup.propTypes = {
  width: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
    PropTypes.array,
  ]),
  isDistributedEvenly: PropTypes.bool,
  children: PropTypes.array,
  variant: PropTypes.string,
}

// List of components can be found here: https://github.com/chakra-ui/chakra-ui/tree/main/packages/components/theme/src/components
// before customizing chakra-ui component via components be sure it exists in component list

const text = {
  variants: {
    "text-lg": {
      fontSize: ["1.125rem", null, "1.5rem"],
      lineHeight: ["1.625rem", null, "2.25rem"],
    },
    "text-md": {
      fontSize: ["1rem", null, "1.25rem"],
      lineHeight: ["1.5rem", null, "1.75rem"],
    },
    "text-sm": {
      fontSize: ["0.875rem", null, "1rem"],
      lineHeight: ["1.25rem", null, "1.5rem"],
    },
    "text-xs": {
      fontSize: ["0.75rem", null, "0.875rem"],
      lineHeight: ["1.125rem", null, "1.25rem"],
    },
  },
}

const heading = {
  variants: {
    "hero-heading-one": {
      fontSize: ["2.5rem", null, "4rem"],
      lineHeight: ["3.125rem", null, "5rem"],
      fontWeight: "bold",
    },
    "hero-heading-two": {
      fontSize: ["2rem", null, "3.5rem"],
      lineHeight: ["2.5rem", null, "4.375rem"],
      fontWeight: "bold",
    },
    "heading-one": {
      fontSize: ["1.75rem", null, "2.5rem"],
      lineHeight: ["2.375rem", null, "3.375rem"],
      fontWeight: "bold",
    },
    "heading-two": {
      fontSize: ["1.625rem", null, "2rem"],
      lineHeight: ["2.125rem", null, "2.75rem"],
      fontWeight: "bold",
    },
    "heading-three": {
      fontSize: ["1.375rem", null, "1.75rem"],
      lineHeight: ["1.875rem", null, "2.375rem"],
      fontWeight: "bold",
    },
    "heading-four": {
      fontSize: ["1.25rem", null, "1.5rem"],
      lineHeight: ["1.75rem", null, "2rem"],
      fontWeight: "bold",
    },
    "heading-five": {
      fontSize: ["1.125rem", null, "1.25rem"],
      lineHeight: ["1.625rem", null, "1.75rem"],
      fontWeight: "bold",
    },
    "heading-six": {
      fontSize: "1rem",
      lineHeight: "1.375rem",
      fontWeight: "bold",
    },
    kicker: {
      fontSize: ["0.875rem", null, "1rem"],
      lineHeight: ["1.25rem", null, "1.5rem"],
      fontWeight: "600",
      textTransform: "uppercase",
    },
  },
}

const components = {
  Link: {
    variants: {
      standalone: {
        color: "inherit",
        _hover: {
          textDecoration: "none",
        },
      },
      navigation: {
        _hover: {
          textDecoration: "none",
          color: "primary.500",
        },
      },
      inline: {
        color: "teal.400",
        textDecoration: "none",
        _visited: {
          color: "gray.600",
          textDecoration: "none",
        },
        _hover: {
          textDecoration: "underline",
        },
      },
    },
  },

  Heading: heading,
  Text: text,

  List: {
    parts: ["item"],
    baseStyle: {
      item: {},
    },
  },

  Avatar: {
    baseStyle: {
      bg: "gray.100",
    },
    defaultProps: {
      size: "md",
    },
  },

  Badge: {
    baseStyle: {
      py: "0.125rem",
      px: "0.5rem",
      ...heading.variants.kicker,
    },
  },

  Button: {
    variants: {
      outline: {
        border: "2px solid",
      },
    },
  },

  Checkbox: {
    baseStyle: {
      colorScheme: "blurble",
    },
  },

  Divider: {
    baseStyle: {
      my: 0,
      borderColor: "gray.100",
    },
  },

  Drawer: {
    parts: ["dialog"],
    baseStyle: {
      dialog: {
        bg: "white",
      },
    },
  },

  Input: {
    baseStyle: {
      bgColor: "white",
      focusBorderColor: "primary.500",
      _placeholder: { color: "gray.500" },
    },
  },

  Menu: {
    parts: ["list", "item"],
    baseStyle: {
      list: {
        placement: "bottom-start",
        border: "none",
        py: "sm",
      },
      item: {
        py: "0.375rem",
      },
    },
  },

  Modal: {
    parts: ["dialog", "body", "closeButton"],
    baseStyle: {
      dialog: { borderRadius: "lg" },
      body: { py: "md" },
      closeButton: { borderRadius: "100%", top: "0.75rem" },
    },
  },

  "Number-Input": {
    parts: ["field"],
    baseStyle: {
      field: {
        bgColor: "white",
        focusBorderColor: "primary.500",
      },
    },
  },

  NumberInputStepper: {
    baseStyle: {
      m: "0.125rem",
      height: "calc(100% - 0.25rem)",
      bgColor: "white",
    },
  },

  Select: {
    baseStyle: {
      bgColor: "white",
      focusBorderColor: "primary.500",
    },
  },

  Spinner: {
    baseStyle: {
      thickness: "0.125rem",
    },
  },

  Switch: {
    baseStyle: {
      color: "blurble",
    },
  },

  Tabs: {
    parts: ["root", "tab"],
    baseStyle: {
      root: {
        color: "blurble",
      },
      tab: { fontWeight: 600 },
    },
    sizes: {
      xs: {
        tab: {
          py: 0,
          px: 3,
          fontSize: "xs",
        },
      },
    },
  },

  Textarea: {
    baseStyle: {
      bgColor: "white",
      focusBorderColor: "primary.500",
      _placeholder: { color: "gray.500" },
      lineHeight: "normal",
    },
  },

  Tooltip: {
    baseStyle: {
      borderRadius: "sm",
      px: "sm",
      py: "xs",
      boxShadow: "sm",
      bg: "white",
      color: "inherit",
      ...text.variants["text-xs"],
    },
  },
}

export default components

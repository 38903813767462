import React, { useMemo, ComponentType, ReactElement } from "react"
import { I18nextProvider, I18nextProviderProps } from "react-i18next"
import hoistNonReactStatics from "hoist-non-react-statics"
import { i18n as I18nInstance } from "i18next"

import { createClient } from "./createClient"

export let globalI18n: I18nInstance | null = null

interface PageProps {
  i18n?: {
    initialLanguage: string
    initialI18nStore: unknown
  }
}

interface AppWithTranslationProps {
  pageProps?: PageProps
}

export const withI18n = <P extends object>(
  ComposedComponent: ComponentType<P>,
  config?: object
) => {
  const AppWithTranslation = (
    props: P & AppWithTranslationProps
  ): ReactElement => {
    const { pageProps } = props
    const initialLanguage = pageProps?.i18n?.initialLanguage
    const initialI18nStore = pageProps?.i18n?.initialI18nStore

    const { i18n } = createClient({
      lng: initialLanguage,
      resources: initialI18nStore,
      ...config,
    })

    useMemo(() => {
      globalI18n = i18n
    }, [i18n])

    const providerProps: I18nextProviderProps = { i18n }

    return (
      <I18nextProvider {...providerProps}>
        <ComposedComponent {...(props as P)} />
      </I18nextProvider>
    )
  }

  return hoistNonReactStatics(AppWithTranslation, ComposedComponent)
}

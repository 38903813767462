class ClientError extends Error {
  response
  request

  constructor(response, request) {
    // const message = `${ClientError.extractMessage(response)}: ${JSON.stringify({
    //   response,
    //   request,
    // })}`
    const message = `${ClientError.extractMessage(response)}`

    super(message)

    this.response = response
    this.request = request

    // this is needed as Safari doesn't support .captureStackTrace
    if (typeof Error.captureStackTrace === "function") {
      Error.captureStackTrace(this, ClientError)
    }
  }

  static extractMessage(response) {
    try {
      return response.errors[0]?.message
    } catch (e) {
      return `GraphQL Error (Code: ${response.status})`
    }
  }
}

type RequestOptions = {
  query: string
  variables?: Record<string, unknown>
  url?: string
  signal?: AbortSignal
}

class GraphQLClient {
  url
  options

  constructor(url, options?: Record<string, unknown>) {
    this.url = url
    this.options = options || {}
  }

  async request({ query, variables, url, signal }: RequestOptions) {
    const { headers, ...rest } = this.options

    const body = JSON.stringify({ query, variables })

    const response = await fetch(url || this.url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        ...headers,
      },
      body,
      signal,
      ...rest,
    })

    const result = await getResult(response)

    if (response.ok && !result.errors && result.data) {
      return result.data
    } else {
      const errorResult =
        typeof result === "string" ? { error: result } : result
      throw new ClientError(
        { ...errorResult, status: response.status },
        { query, variables }
      )
    }
  }

  setHeaders(headers) {
    this.options.headers = headers

    return this
  }

  setHeader(key, value) {
    const { headers } = this.options

    if (headers) {
      headers[key] = value
    } else {
      this.options.headers = { [key]: value }
    }
    return this
  }
}

async function getResult(response) {
  const contentType = response.headers.get("Content-Type")
  if (contentType && contentType.startsWith("application/json")) {
    return response.json()
  } else {
    return response.text()
  }
}

const gql = (chunks, ...variables) =>
  chunks.reduce(
    (accumulator, chunk, index) =>
      `${accumulator}${chunk}${index in variables ? variables[index] : ""}`,
    ""
  )

export { GraphQLClient, gql }

import React from "react"
import PropTypes from "prop-types"
import { useTheme } from "@chakra-ui/react"

import { Stack, Container, Box, Divider } from "../../ui"

export const Section = ({
  variant = "default",
  maxW = "container.xl",
  children,
  withDivider,
  ...restProps
}) => {
  const {
    customComponents: { section },
  } = useTheme()

  const py = section[variant]?.py
  const spacing = section[variant]?.spacing

  return (
    <>
      <Box as="section" py={py} {...restProps}>
        <Container maxW={maxW}>
          <Stack spacing={spacing} shouldWrapChildren>
            {children}
          </Stack>
        </Container>
      </Box>
      {withDivider && (
        <Container maxW={maxW}>
          <Divider />
        </Container>
      )}
    </>
  )
}

Section.propTypes = {
  children: PropTypes.any,
  maxW: PropTypes.string,
  spacing: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  variant: PropTypes.oneOf(["default", "sm", "md", "lg", "xl"]),
  withDivider: PropTypes.bool,
}

import PropTypes from "prop-types"
import React from "react"
import { Container as CContainer } from "@chakra-ui/react"

export const Container = ({ children, ...restProps }) => (
  <CContainer px={["sm", "md"]} mx="auto" maxW="container.xl" {...restProps}>
    {children}
  </CContainer>
)

Container.propTypes = {
  children: PropTypes.any,
  spacing: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

const radii = {
  sm: "0.25rem",
  md: "0.5rem",
  lg: "0.75rem",
  xl: "1rem",
  pill: "99rem",
  circle: "100%",
  full: "99rem",
}

export default radii

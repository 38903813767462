import React from "react"
import Script from "next/script"
import { useRouter } from "next/router"
import { Global } from "@emotion/react"
import { withI18n } from "datastory-shared/utils/i18n/withI18n"
import { PageContext } from "datastory-shared/utils/page"
import { ChakraProvider, AntProvider, ViewportLock } from "datastory-shared/ui"
import { SiteMeta, CookieConsent } from "datastory-shared/sections"
import { ConditionalWrap } from "datastory-shared/utils/components/ConditionalWrap"
import svSE from "antd/locale/sv_SE"

import Error from "./_error"

import { theme, themeAnt, globalStyles } from "utils/theme"
import { PageContent } from "components/layouts"
import { SiteHeader, SiteFooter } from "components/blocks"

// eslint-disable-next-line react/prop-types
const DatastoryApp = ({ Component, pageProps = {}, err }) => {
  const router = useRouter()
  const { pageData = {}, isViewportLocked, headerProps } = pageProps
  const isEmbedded = router.query.embed !== undefined
  const showHeaderAndFooter = !isEmbedded

  return (
    <>
      <Script
        strategy="lazyOnload"
        src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`}
      />
      <Script strategy="lazyOnload" id="ga-analytics">
        {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
        `}
      </Script>
      <PageContext.Provider value={pageData}>
        <SiteMeta />
        <ChakraProvider theme={theme}>
          <AntProvider
            theme={themeAnt}
            locale={router.locale === "sv" ? svSE : undefined}
          >
            <Global styles={globalStyles} />
            <ConditionalWrap
              condition={isViewportLocked}
              wrap={children => <ViewportLock>{children}</ViewportLock>}
            >
              <>
                {showHeaderAndFooter && <SiteHeader {...headerProps} />}
                <PageContent>
                  {pageProps.statusCode ? (
                    <Error statusCode={pageProps.statusCode} />
                  ) : (
                    <Component {...pageProps} err={err} />
                  )}
                </PageContent>
                {showHeaderAndFooter && !isViewportLocked && <SiteFooter />}
                <CookieConsent
                  buttonStyle={{ colorScheme: "green" }}
                  background="text"
                />
              </>
            </ConditionalWrap>
          </AntProvider>
        </ChakraProvider>
      </PageContext.Provider>
    </>
  )
}

export default withI18n(DatastoryApp)

import { theme } from "@chakra-ui/react"

import { colorScales, dataScales } from "./colorScales"

const colors = {
  ...theme.colors,

  white: "#FFFFFF",
  background: "#FFFFFF",
  dark: colorScales.gray["900"],

  text: "#1E1832",
  secondaryText: colorScales.gray["500"],

  primary: colorScales.blurble,
  success: colorScales.green,
  danger: colorScales.red,
  warning: colorScales.yellow,
  disabled: colorScales.gray,
  brand: colorScales.blurble,

  ...colorScales,
  dataScales,
}

export default colors

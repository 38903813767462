import { extendTheme } from "@chakra-ui/react"

import borders from "./borders"
import breakpoints from "./breakpoints"
import colors from "./colors"
import components from "./components"
import customComponents from "./customComponents"
import radii from "./radii"
import shadows from "./shadows"
import sizes from "./sizes"
import space from "./space"
import transition from "./transition"
import typography from "./typography"
import zIndex from "./z-index"

export { themeAnt } from "./ant"
export { globalStyles } from "./globalStyles"

export const theme = extendTheme({
  borders,
  breakpoints,
  colors,
  components,
  customComponents,
  radii,
  shadows,
  sizes,
  space,
  transition,
  ...typography,
  zIndex,

  /* To be deprecated */

  buttonPaddings: {
    sm: "0.75rem",
    md: "1rem",
    lg: "1.25rem",
  },
  ctaPaddings: {
    sm: "1.25rem",
    md: "1.5rem",
    lg: "2rem",
  },

  containers: {
    maxWidths: {
      sm: "56rem",
      md: "105rem",
      lg: "105rem",
    },

    paddings: {
      sm: ["1rem", null, "2.5rem", null, "6rem"],
      md: ["1rem", null, "2.5rem", null, "6rem"],
      lg: ["1rem", null, "2.5rem"],
    },
  },

  semanticTokens: {
    colors: {
      "chakra-body-text": { _light: colors.text },
    },
  },

  /* Stop deprecate */
})

import React from "react"
import { Text as CText, Heading as CHeading } from "@chakra-ui/react"

import { forwardRef } from "../Hooks"

export const Text = forwardRef(
  ({ muted, variant = "text-md", ...restProps }, ref) => {
    if (muted) restProps.opacity = 0.6
    return <CText ref={ref} variant={variant} {...restProps} />
  }
)

export const TextLG = forwardRef((props, ref) => (
  <Text ref={ref} variant="text-lg" {...props} />
))

export const TextSM = forwardRef((props, ref) => (
  <Text ref={ref} variant="text-sm" {...props} />
))

export const TextXS = forwardRef((props, ref) => (
  <Text ref={ref} variant="text-xs" {...props} />
))

export const Heading = forwardRef((props, ref) => {
  const { muted, variant = "heading-two", ...restProps } = props
  if (muted) restProps.opacity = 0.6

  return <CHeading ref={ref} variant={variant} {...restProps} />
})

export const HeroHeadingOne = forwardRef((props, ref) => (
  <Heading ref={ref} as="h1" variant="hero-heading-one" {...props} />
))

export const HeroHeadingTwo = forwardRef((props, ref) => (
  <Heading ref={ref} as="h2" variant="hero-heading-two" {...props} />
))

export const HeadingOne = forwardRef((props, ref) => (
  <Heading ref={ref} as="h1" variant="heading-one" {...props} />
))

export const HeadingTwo = forwardRef((props, ref) => (
  <Heading ref={ref} as="h2" variant="heading-two" {...props} />
))

export const HeadingThree = forwardRef((props, ref) => (
  <Heading ref={ref} as="h3" variant="heading-three" {...props} />
))

export const HeadingFour = forwardRef((props, ref) => (
  <Heading ref={ref} as="h4" variant="heading-four" {...props} />
))

export const HeadingFive = forwardRef((props, ref) => (
  <Heading ref={ref} as="h5" variant="heading-five" {...props} />
))

export const HeadingSix = forwardRef((props, ref) => (
  <Heading ref={ref} as="h6" variant="heading-six" {...props} />
))

export const Kicker = forwardRef((props, ref) => (
  <Heading ref={ref} as="p" variant="kicker" {...props} />
))

import React from "react"
import Image from "next/image"

import logo from "./datastory.png"
import lightLogo from "./datastory-light.png"
import cloudLogo from "./datastory-cloud.png"
import orgLogo from "./datastory-org.png"
import orgLogoLight from "./datastory-org-light.png"

export const DatastoryLogo = ({ color = "dark", ...props }) => {
  const src = color === "dark" ? logo : lightLogo
  return (
    <Image src={src} alt="Datastory Logo" width={150} height={1} {...props} />
  )
}

export const DatastoryOrgLogo = ({ color = "dark" }) => {
  const src = color === "dark" ? orgLogo : orgLogoLight
  return <Image src={src} alt="Datastory Org" width={200} height={1} />
}

export const CloudLogo = props => (
  <Image
    src={cloudLogo}
    alt="Datastory Cloud"
    width={283}
    height={38}
    {...props}
  />
)

import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import XHR from "i18next-xhr-backend"

import { isBrowser } from "../../utils/browser"

import { config as defaultConfig } from "./index"

let globalInstance

export const createClient = config => {
  const options = { ...defaultConfig, ...config }
  let instance
  let initPromise

  if (isBrowser) {
    instance = i18n.createInstance(options)
  } else {
    if (!globalInstance) {
      globalInstance = i18n.createInstance(options)
      instance = globalInstance
    } else {
      instance = globalInstance.cloneInstance({
        ...options,
        initImmediate: false,
      })
    }
  }

  if (!instance.isInitialized) {
    instance.use(XHR).use(initReactI18next)
    initPromise = instance.init(options)
  } else {
    initPromise = Promise.resolve(i18n.t)
  }

  return { i18n: instance, initPromise }
}

import React from "react"
import PropTypes from "prop-types"
import { captureException, flush } from "@sentry/nextjs"
import { useTranslation } from "datastory-shared/utils/i18n"
import { Container, TextSM, HeadingThree, Stack } from "datastory-shared/ui"

const Error = ({ statusCode, hasGetInitialPropsRun, err }) => {
  const { t } = useTranslation()

  if (!hasGetInitialPropsRun && err) {
    // getInitialProps is not called in case of
    // https://github.com/vercel/next.js/issues/8592. As a workaround, we pass
    // err via _app.js so it can be captured
    captureException(err)
    // Flushing is not required in this case as it only happens on the client
  }

  return (
    <Container
      size="sm"
      minHeight="50vh"
      textAlign="center"
      pt={["3.5rem", "5.5rem"]}
    >
      <Stack spacing="md">
        {statusCode && <HeadingThree as="h1">{statusCode}</HeadingThree>}
        <TextSM>{t("og_c.error__500")}</TextSM>
      </Stack>
    </Container>
  )
}

Error.getInitialProps = async ({ res, err, asPath }) => {
  const props = {
    statusCode: res ? res.statusCode : err ? err.statusCode : 404,
    // Workaround for https://github.com/vercel/next.js/issues/8592, mark when
    // getInitialProps has run
    hasGetInitialPropsRun: true,
  }

  // Running on the server, the response object (`res`) is available.
  //
  // Next.js will pass an err on the server if a page's data fetching methods
  // threw or returned a Promise that rejected
  //
  // Running on the client (browser), Next.js will provide an err if:
  //
  //  - a page's `getInitialProps` threw or returned a Promise that rejected
  //  - an exception was thrown somewhere in the React lifecycle (render,
  //    componentDidMount, etc) that was caught by Next.js's React Error
  //    Boundary. Read more about what types of exceptions are caught by Error
  //    Boundaries: https://reactjs.org/docs/error-boundaries.html

  if (err) {
    captureException(err)

    // Flushing before returning is necessary if deploying to Vercel, see
    // https://vercel.com/docs/platform/limits#streaming-responses
    await flush(2000)

    return props
  }

  // If this point is reached, getInitialProps was called without any
  // information about what the error might be. This is unexpected and may
  // indicate a bug introduced in Next.js, so record it in Sentry
  captureException(
    new Error(`_error.js getInitialProps missing data at path: ${asPath}`)
  )
  await flush(2000)

  return props
}

Error.propTypes = {
  statusCode: PropTypes.number,
  hasGetInitialPropsRun: PropTypes.bool,
  err: PropTypes.object,
}

export default Error

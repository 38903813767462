const IMAGE_DOMAINS = [
  "datastory-images.s3.amazonaws.com",
  "datastory-images.s3.eu-central-1.amazonaws.com",
]

// list of languages supported by the website
// needs to be updated whenever we add
// a new language in the CMS
const allLanguages = [
  "en",
  "sv",
  "es",
  "fr",
  "de",
  "ar",
  "zh",
  "ja",
  "pt",
  "ru",
  "pl",
  "it",
]

const defaultLanguage = "en"

module.exports = {
  allLanguages,
  defaultLanguage,
  IMAGE_DOMAINS,
}

import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Global, css } from "@emotion/react"

import { Box } from "../Box"

export const ViewportLock = ({ children, ...restProps }) => {
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (!window) return
    document.body.classList.add("lock-body")
    document.documentElement.classList.add("lock-html")
    const lockScroll = () => {
      // Prevent window scrolling on iOS
      window.scrollTo(0, 0)
    }
    const handleResize = () => {
      setHeight(window.innerHeight)
    }
    window.addEventListener("scroll", lockScroll)
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => {
      document.body.classList.remove("lock-body")
      document.documentElement.classList.remove("lock-html")
      window.removeEventListener("scroll", lockScroll)
      window.removeEventListener("resize", handleResize)
    }
  }, [])

  return (
    <Box
      position="relative"
      overflow="hidden"
      height={`${height}px`}
      {...restProps}
    >
      <Global
        styles={css`
          .lock-html {
            overflow: hidden;
          }
          .lock-body {
            position: fixed !important;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            overflow: hidden;
          }
        `}
      />
      {children}
    </Box>
  )
}

ViewportLock.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
}

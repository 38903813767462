import React from "react"
import { useHubspotForm } from "next-hubspot"

import { Box } from "../../ui"

const HubspotForm = () => {
  useHubspotForm({
    region: "eu1",
    portalId: "143860275",
    formId: "05942381-34ab-448c-a932-79235d5fd1ab",
    target: "#hubspot-form-wrapper",
  })

  return <div id="hubspot-form-wrapper" />
}

export const NewsletterSignupForm = () => {
  return (
    <Box>
      <HubspotForm />
    </Box>
  )
}

import React, { useState, useContext } from "react"
import PropTypes from "prop-types"

import { PageContext } from "../../utils/page"
import { useTranslation } from "../../utils/i18n"

import { NewsletterSignupForm } from "./Form"

const validateEmail = email => {
  const re =
    //eslint-disable-next-line
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

export const NewsletterSignup = props => {
  const { t } = useTranslation()
  const { locale: language } = useContext(PageContext)

  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [messageVariant, setMessageVariant] = useState("info")

  const messages = {
    success: t("newsletter_complete"),
    memberExists: t("newsletter_already-subscribed"),
    error: t("newsletter_error"),
    invalidEmail: t("newsletter_invalid-email"),
  }

  const handleSubmit = e => {
    e.preventDefault()

    if (!validateEmail(email)) {
      setMessage(messages.invalidEmail)
      setMessageVariant("info")
      return
    }

    fetch("/api/newsletter", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email_address: email,
        status: "subscribed",
        language,
        tags: props.tags,
      }),
    })
      .then(response => response.json())
      .then(({ message }) => {
        setMessage(messages[message])
        setMessageVariant(message !== "error" ? "success" : "warning")
      })
      .catch(() => {
        setMessage(messages.error)
        setMessageVariant("warning")
      })
  }

  const handleValidateEmail = e => {
    setEmail(e.target.value)
    setMessage("")
  }

  const handleCloseNotification = e => {
    e.preventDefault()
    setMessage("")
  }

  return (
    <NewsletterSignupForm
      handleSubmit={handleSubmit}
      handleValidateEmail={handleValidateEmail}
      handleCloseNotification={handleCloseNotification}
      message={message}
      messageVariant={messageVariant}
      {...props}
    />
  )
}

NewsletterSignup.propTypes = {
  tags: PropTypes.array,
}

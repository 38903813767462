import { GraphQLClient } from "../graphql"
import { defaultLanguage } from "../../config"

export { useTranslation } from "./useTranslation"

const endpoint = "https://datastory-cloud-v1.stellate.sh"
// const endpoint = "https://datastory-cloud-v1.hasura.app/v1/graphql"
const client = new GraphQLClient(endpoint)

export const config = {
  fallbackLng: defaultLanguage,
  load: "languageOnly",
  ns: ["og_c"],
  defaultNS: "og_c",
  // debug: process.env.NODE_ENV !== "production",
  interpolation: {
    escapeValue: false, // not needed for react
  },
  react: {
    useSuspense: false,
  },
  // overwrite default backend behavior and load translations from Hasura
  // this also allows to reuse the same backend on the server
  backend: {
    loadPath: "{{lng}}::{{ns}}",
    parse: result => result,
    ajax: async (url, options, callback) => {
      const [language, ns] = url.split("::")
      // replace _ with : to match knowledge graph namespace separator in the db
      // we can't have : as a separator in i18n because it uses it to identify
      // namespace to which a key belongs (e.g. c:title), we'd run into an
      // issue then ending up with og:c:title
      const id = ns.replace("_", ":")

      try {
        const { entity } = await client.request({
          query: `query TranslationQuery($id: String!) {
              entity: entity_by_pk(id: $id) {
                name: triple_by_subject(
                  where: { property: { _eq: "og:name" } }
                ) {
                  id
                  i18n: postgres_i18n(path: "${language}")
                }
                description: triple_by_subject(
                  where: { property: { _eq: "og:description" } }
                ) {
                  id
                  i18n: postgres_i18n(path: "${language}")
                }
                i18nString: triple_by_subject(
                  where: { property: { _eq: "og:i18nString" } }
                ) {
                  id
                  i18n_key
                  i18n: postgres_i18n(path: "${language}")
                }
              }
            }
          `,
          variables: { id },
        })
        const result = {}
        if (entity?.name[0]?.i18n) result.name = entity.name[0].i18n
        if (entity?.description[0]?.i18n)
          result.description = entity.description[0].i18n
        entity?.i18nString?.forEach(p => {
          result[p.i18n_key] = p.i18n
        })
        callback(result, { status: 200 })
      } catch (error) {
        callback(null, { status: 404 })
      }
    },
  },
}

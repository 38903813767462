const space = {
  px: "1px",
  0: "0",
  1: "0.25rem",
  2: "0.5rem",
  3: "0.75rem",
  4: "1rem",
  5: "1.25rem",
  6: "1.5rem",
  8: "2rem",
  10: "2.5rem",
  12: "3rem",
  16: "4rem",
  20: "5rem",
  24: "6rem",
  32: "8rem",
  40: "10rem",
  48: "12rem",
  56: "14rem",
  64: "16rem",

  xxs: "0.25rem",
  xs: "0.5rem",
  sm: "0.75rem",
  md: "1.25rem",
  lg: "2.5rem",
  xl: "5rem",
  xxl: "7rem",
  "2xl": "7rem",
}

export default space

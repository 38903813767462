// customComponents are not chakra components and can not be customized as default components
const customComponents = {
  grids: {
    templateColumns: [
      "repeat(4, 1fr)",
      "repeat(6, 1fr)",
      "repeat(12, 1fr)",
      "repeat(12, 1fr)",
      "repeat(12, 1fr)",
    ],
    columnGap: ["1rem", "1rem", "1.5rem", "1.5rem", "1.5rem"],
    px: ["1rem", "1rem", "2.5rem", "2.5rem", "5rem"],
  },

  gridItem: {
    colSpan: {
      "4-6-2": [4, 6, 2],
      "4-6-4-3": [4, 6, 4, 3],
      "4-3-4-3": [4, 3, 4, 3],
      "4-6-4": [4, 6, 4],
      "4-6-5": [4, 6, 5],
      "4-6": [4, 6],
      "4-6-7": [4, 6, 7],
      "4-6-8": [4, 6, 8],
      "4-6-9": [4, 6, 9],
      "4-6-10": [4, 6, 10],
    },
  },

  simpleGrid: {
    columns: 12,
    spacingX: ["1rem", null, "1.5rem"],
    spacingY: ["1rem", null, "1.5rem"],
  },

  checkboxGroup: {
    colorScheme: "blurble",
  },

  radioGroup: {
    colorScheme: "blurble",
  },

  section: {
    default: {
      py: ["2rem", "3rem"],
      spacing: [".75rem", "1.25rem"],
    },
    lg: {
      py: ["3rem", "4rem"],
      spacing: [".75rem", "1.25rem"],
    },
    xl: {
      py: ["3rem", "4rem"],
      spacing: ["1.25rem", "1.75rem"],
    },
  },

  image: {
    baseUrl: "https://datastory-images.s3.amazonaws.com",
  },

  document: {
    baseUrl: "https://datastory-documents.s3.amazonaws.com",
  },
}

export default customComponents

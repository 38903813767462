import PropTypes from "prop-types"
import Router from "next/router"
import NProgress from "nprogress"
import {
  Box,
  Stack,
  Link,
  Flex,
  Text,
  DatastoryOrgLogo,
  HStack,
  VStack,
  HeadingFour,
  ArrowRightIcon,
} from "datastory-shared/ui"

import { MobileSiteNav } from "datastory-shared/sections"

Router.onRouteChangeStart = () => NProgress.start()
Router.onRouteChangeComplete = () => NProgress.done()
Router.onRouteChangeError = () => NProgress.done()

export const SiteHeader = ({ color = "inherit", bg = "transparent" }) => {
  return (
    <Box color={color} bg={bg}>
      <Stack
        isInline
        spacing="1.5rem"
        mx="auto"
        px={["sm", null, "xl"]}
        py={["sm", null, "md"]}
        height={77}
        alignItems="center"
        justifyContent="space-between"
        borderBottom="2px solid"
        borderColor="#F8F6F4"
        fontWeight={600}
      >
        <Link href="/" aria-label="home-page">
          <DatastoryOrgLogo />
        </Link>
        <HStack spacing="1.5rem" display={["none", null, null, "flex"]}>
          <Link variant="navigation" href="/services">
            <Text>Interactive tools</Text>
          </Link>
          <Link variant="navigation" href="/academy/signup">
            <Text>Datastory Academy {"🇸🇪"}</Text>
          </Link>

          <Link variant="navigation" href="/about">
            <Text>About</Text>
          </Link>
        </HStack>

        <Box display={["flex", null, null, "none"]}>
          <MobileSiteNav
            header={({ onClose }) => (
              <Flex
                alignItems="center"
                justifyContent="space-between"
                flex="2 0 0"
              >
                <Link
                  flex="none"
                  href="/"
                  aria-label="home-page"
                  onClick={onClose}
                >
                  <DatastoryOrgLogo />
                </Link>
              </Flex>
            )}
          >
            {({ onClose }) => (
              <>
                <VStack alignItems="left" spacing="4" p={5}>
                  <Link variant="navigation" href="/services" onClick={onClose}>
                    <HeadingFour fontWeight={600}>
                      Interactive tools
                      <ArrowRightIcon mx="xs" />
                    </HeadingFour>
                  </Link>
                  <Link
                    variant="navigation"
                    href="/academy/signup"
                    onClick={onClose}
                  >
                    <HeadingFour fontWeight={600}>
                      Datastory Academy
                      <ArrowRightIcon mx="xs" />
                    </HeadingFour>
                  </Link>
                  <Link variant="navigation" href="/about" onClick={onClose}>
                    <HeadingFour fontWeight={600}>
                      About
                      <ArrowRightIcon mx="xs" />
                    </HeadingFour>
                  </Link>
                </VStack>
              </>
            )}
          </MobileSiteNav>
        </Box>
      </Stack>
    </Box>
  )
}

SiteHeader.propTypes = {
  color: PropTypes.string,
  bg: PropTypes.string,
}

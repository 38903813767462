import {
  Grid,
  Box,
  Flex,
  TextSM,
  Stack,
  Kicker,
  VStack,
  DatastoryOrgLogo,
  Link,
} from "datastory-shared/ui"
import {
  Section,
  NewsletterSignup,
  SocialMediaItem,
} from "datastory-shared/sections"

export const SiteFooter = () => {
  return (
    <Box as="footer" color="white">
      <Section bg="blurble.700">
        <Grid variant="124" py="0" w="100%">
          <DatastoryOrgLogo color="light" />
          <Box>
            <Kicker muted>Company</Kicker>
            <VStack alignItems={"left"} spacing="2" mt="sm">
              <Link href="/services" variant="standalone">
                Interactive tools{" "}
              </Link>
              <Link href="/about" variant="standalone">
                Our story
              </Link>
            </VStack>
          </Box>

          {/* Address */}
          <Stack spacing="sm">
            <Kicker muted>Address</Kicker>
            <Box>
              <Stack spacing="md">
                <TextSM fontWeight={600} as="address">
                  {"Datastory"}
                  <br />
                  {"c/o The Works"}
                  <br />
                  {"Medborgarplatsen 3"}
                  <br />
                  {"118 26 Stockholm"}
                </TextSM>
              </Stack>
            </Box>
          </Stack>
          <Box>
            <Stack spacing="xs">
              <Kicker muted>Contact</Kicker>
              <TextSM fontWeight={600}>
                <a href={`mailto:hello@datastory.org`}>hello@datastory.org</a>
              </TextSM>
            </Stack>

            <Stack
              spacing="sm"
              shouldWrapChildren={true}
              isInline
              mt="md"
              mb="lg"
            >
              {["twitter", "instagram", "facebook", "github"].map(i => (
                <SocialMediaItem
                  iconSize="lg"
                  key={i}
                  type={i}
                  disableText={true}
                  isIconButton={true}
                  isFollowUs={true}
                />
              ))}
            </Stack>
          </Box>
        </Grid>
      </Section>

      <Section bg="blurble.50" maxW="container.xl" py="sm">
        <Flex
          wrap="wrap"
          w="100%"
          alignItems="center"
          justifyContent="center"
          height={["100%", 100]}
        >
          <TextSM
            fontWeight={600}
            color="black"
            w={["100%", null, 2 / 5]}
            py={["md", 0]}
          >
            Subscribe to our newsletter to get monthly technology and content
            updates!{" "}
          </TextSM>
          <Box
            width={["100%", null, "fit-content"]}
            pl={[0, null, "lg"]}
            pb={["xl", null, null, null, 0]}
            mt={["md", null, 0]}
          >
            <NewsletterSignup
              tags={["Datastory Org"]}
              placeholder="Your email"
              buttonText="Subscribe"
              styles={{
                input: {
                  bg: "white",
                  color: "black",
                },
                alert: {
                  color: "black",
                  p: "xs",
                  position: "relative",
                  top: "-10px",
                },
                button: {
                  _hover: { bg: "teal.800" },
                  _focus: { bg: "teal.800" },
                  _active: { bg: "teal.900" },
                  bg: "#511EE9",
                },
                iconButton: {
                  _hover: { bg: "teal.800" },
                  _focus: { bg: "teal.800" },
                  _active: { bg: "teal.900" },
                  bg: "#511EE9",
                  size: "xs",
                },
              }}
            />
          </Box>
        </Flex>
      </Section>
    </Box>
  )
}

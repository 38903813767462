const colorScales = {
  gray: {
    50: "#F4F3F5",
    100: "#E8E8EA",
    200: "#D2D1D6",
    300: "#BBBAC1",
    400: "#A5A3AD",
    500: "#8E8B98",
    600: "#787484",
    700: "#625D70",
    800: "#4B465B",
    900: "#352F47",
  },

  lime: {
    50: "#fdfff0",
    100: "#fcfff0",
    200: "#e7f2bf",
    300: "#d0e691",
    400: "#b8d966",
    500: "#9fcb3f",
    600: "#7ba62b",
    700: "#59801b",
    800: "#3a590e",
    900: "#1f3308",
  },
  green: {
    50: "#f0fff5",
    100: "#e3fced",
    200: "#b1f0cc",
    300: "#84e3b0",
    400: "#5ad698",
    500: "#35c983",
    600: "#22a36b",
    700: "#147d53",
    800: "#0a573a",
    900: "#053022",
  },
  teal: {
    50: "#dcf5f1",
    100: "#95e8dd",
    200: "#69dbd0",
    300: "#42cfc5",
    400: "#1fc2bc",
    500: "#00b6b6",
    600: "#008a8f",
    700: "#006269",
    800: "#003c42",
    900: "#00181c",
  },

  tealDark: {
    50: "#dcf5f1",
    100: "#dcf5f1",
    200: "#dcf5f1",
    300: "#dcf5f1",
    400: "#42cfc5",
    500: "#008a8f",
    600: "#006269",
    700: "#42cfc5",
    800: "#42cfc5",
    900: "#42cfc5",
  },
  cyan: {
    50: "#e6faff",
    100: "#a3eaff",
    200: "#7adcff",
    300: "#52cbff",
    400: "#29b8ff",
    500: "#009cf7",
    600: "#007dd1",
    700: "#0061ab",
    800: "#004785",
    900: "#002f5e",
  },
  blue: {
    50: "#e6f6ff",
    100: "#a3daff",
    200: "#7ac6ff",
    300: "#52aeff",
    400: "#2994ff",
    500: "#0077ff",
    600: "#005ed9",
    700: "#0047b3",
    800: "#00338c",
    900: "#002266",
  },
  blurble: {
    50: "#f5edff",
    100: "#dbc4ff",
    200: "#be9cff",
    300: "#9f73ff",
    400: "#7847f5",
    500: "#511ee9",
    600: "#3610c2",
    700: "#20059c",
    800: "#120075",
    900: "#09004f",
  },
  purple: {
    50: "#f5dcef",
    100: "#e89bd9",
    200: "#db70c9",
    300: "#cf48bd",
    400: "#c225b2",
    500: "#b605ab",
    600: "#8f008a",
    700: "#690069",
    800: "#400042",
    900: "#1a001c",
  },
  pink: {
    50: "#ffe6ee",
    100: "#ffa8c8",
    200: "#fc7eb1",
    300: "#f05196",
    400: "#e32980",
    500: "#d6046e",
    600: "#b0005e",
    700: "#8a004e",
    800: "#63003c",
    900: "#3d0027",
  },
  red: {
    50: "#fff0f0",
    100: "#ffd1d1",
    200: "#ffa8ab",
    300: "#fc7e87",
    400: "#f05161",
    500: "#e32942",
    600: "#bd1934",
    700: "#960c28",
    800: "#70031d",
    900: "#4a0115",
  },
  orange: {
    50: "#fff7eb",
    100: "#ffe6c2",
    200: "#ffd199",
    300: "#ffba70",
    400: "#ffa047",
    500: "#ff841e",
    600: "#d9630f",
    700: "#b34704",
    800: "#8c3100",
    900: "#662000",
  },
  yellow: {
    50: "#fffce6",
    100: "#fff4ab",
    200: "#ffea82",
    300: "#ffde59",
    400: "#ffcf30",
    500: "#ffbd07",
    600: "#d99800",
    700: "#b37700",
    800: "#8c5900",
    900: "#663d00",
  },
}

const dataScales = {
  categorical: {
    default: [
      colorScales.blurble["400"],
      colorScales.pink["400"],
      colorScales.orange["500"],
      colorScales.yellow["400"],
      colorScales.teal["400"],
      colorScales.blue["300"],
      colorScales.blue["600"],
      colorScales.purple["500"],
      colorScales.red["400"],
    ],
  },
  sequential: {
    default: [
      colorScales.blurble["700"],
      colorScales.blurble["300"],
      colorScales.blurble["50"],
    ],
    lime: [
      colorScales.lime["700"],
      colorScales.lime["300"],
      colorScales.lime["50"],
    ],
    green: [
      colorScales.green["700"],
      colorScales.green["300"],
      colorScales.green["50"],
    ],
    blue: [
      colorScales.blue["700"],
      colorScales.blue["300"],
      colorScales.blue["50"],
    ],
    purple: [
      colorScales.purple["700"],
      colorScales.purple["300"],
      colorScales.purple["50"],
    ],
    pink: [
      colorScales.pink["700"],
      colorScales.pink["300"],
      colorScales.pink["50"],
    ],
    red: [
      colorScales.red["600"],
      colorScales.red["300"],
      colorScales.red["50"],
    ],
    orange: [
      colorScales.orange["600"],
      colorScales.yellow["200"],
      colorScales.yellow["50"],
    ],
    sunset: [
      colorScales.purple["600"],
      colorScales.pink["400"],
      colorScales.yellow["300"],
    ],
  },
  diverging: {
    default: [colorScales.pink["500"], colorScales.lime["500"]],
    pink_cyan: [colorScales.pink["500"], colorScales.cyan["500"]],
    yellow_teal: [colorScales.yellow["500"], colorScales.teal["500"]],
    orange_teal: [colorScales.orange["500"], colorScales.teal["500"]],
    red_lime: [colorScales.red["500"], colorScales.lime["500"]],
    orange_blue: [colorScales.orange["500"], colorScales.blue["600"]],
  },

  whiteAlpha: {
    50: "rgba(255, 255, 255, 0.04)",
    100: "rgba(255, 255, 255, 0.06)",
    200: "rgba(255, 255, 255, 0.08)",
    300: "rgba(255, 255, 255, 0.16)",
    400: "rgba(255, 255, 255, 0.24)",
    500: "rgba(255, 255, 255, 0.36)",
    600: "rgba(255, 255, 255, 0.48)",
    700: "rgba(255, 255, 255, 0.64)",
    800: "rgba(255, 255, 255, 0.80)",
    900: "rgba(255, 255, 255, 0.92)",
  },

  blackAlpha: {
    50: "rgba(0, 0, 0, 0.04)",
    100: "rgba(0, 0, 0, 0.06)",
    200: "rgba(0, 0, 0, 0.08)",
    300: "rgba(0, 0, 0, 0.16)",
    400: "rgba(0, 0, 0, 0.24)",
    500: "rgba(0, 0, 0, 0.36)",
    600: "rgba(0, 0, 0, 0.48)",
    700: "rgba(0, 0, 0, 0.64)",
    800: "rgba(0, 0, 0, 0.80)",
    900: "rgba(0, 0, 0, 0.92)",
  },

  linkedin: {
    50: "#E8F4F9",
    100: "#CFEDFB",
    200: "#9BDAF3",
    300: "#68C7EC",
    400: "#34B3E4",
    500: "#00A0DC",
    600: "#008CC9",
    700: "#0077B5",
    800: "#005E93",
    900: "#004471",
  },

  facebook: {
    50: "#E8F4F9",
    100: "#D9DEE9",
    200: "#B7C2DA",
    300: "#6482C0",
    400: "#4267B2",
    500: "#385898",
    600: "#314E89",
    700: "#29487D",
    800: "#223B67",
    900: "#1E355B",
  },

  messenger: {
    50: "#D0E6FF",
    100: "#B9DAFF",
    200: "#A2CDFF",
    300: "#7AB8FF",
    400: "#2E90FF",
    500: "#0078FF",
    600: "#0063D1",
    700: "#0052AC",
    800: "#003C7E",
    900: "#002C5C",
  },

  whatsapp: {
    50: "#dffeec",
    100: "#b9f5d0",
    200: "#90edb3",
    300: "#65e495",
    400: "#3cdd78",
    500: "#22c35e",
    600: "#179848",
    700: "#0c6c33",
    800: "#01421c",
    900: "#001803",
  },

  twitter: {
    50: "#E5F4FD",
    100: "#C8E9FB",
    200: "#A8DCFA",
    300: "#83CDF7",
    400: "#57BBF5",
    500: "#1DA1F2",
    600: "#1A94DA",
    700: "#1681BF",
    800: "#136B9E",
    900: "#0D4D71",
  },
}

export { colorScales, dataScales }

import React from "react"
import PropTypes from "prop-types"

import { forwardRef } from "../../Hooks"

import {
  Grid as DefaultGrid,
  SimpleGrid,
  Grid123,
  Grid12,
  Grid124,
  Grid24,
} from "./Components"

export const Grid = forwardRef(({ variant, ...props }, ref) => {
  if (variant === "simple") return <SimpleGrid ref={ref} {...props} />
  if (variant === "12") return <Grid12 ref={ref} {...props} />
  if (variant === "24") return <Grid24 ref={ref} {...props} />
  if (variant === "123") return <Grid123 ref={ref} {...props} />
  if (variant === "124") return <Grid124 ref={ref} {...props} />

  return <DefaultGrid ref={ref} {...props} />
})

Grid.propTypes = {
  variant: PropTypes.oneOf(["default", "simple", "12", "24", "123", "124"]),
}

import React from "react"
import PropTypes from "prop-types"
import {
  SimpleGrid as CSimpleGrid,
  Grid as CGrid,
  useTheme,
} from "@chakra-ui/react"

import { forwardRef } from "../../Hooks"

// Use theme customComponents file to allow default props for non-chakra components
export const Grid = forwardRef(
  (
    { children, disableMaxWidth, maxWidth = "106rem", centering, ...props },
    ref
  ) => {
    const theme = useTheme()
    const {
      customComponents: { grids },
    } = theme
    const { templateColumns, columnGap, px } = grids

    return (
      <CGrid
        maxWidth={!disableMaxWidth && maxWidth}
        ml={centering === "left" ? 0 : "auto"}
        mr={centering === "right" ? 0 : "auto"}
        templateColumns={templateColumns}
        columnGap={columnGap}
        px={px}
        ref={ref}
        {...props}
      >
        {children}
      </CGrid>
    )
  }
)

Grid.propTypes = {
  children: PropTypes.node,
  disableMaxWidth: PropTypes.bool,
  maxWidth: PropTypes.string,
  centering: PropTypes.string,
}

export const SimpleGrid = ({ ...props }) => {
  return (
    <CSimpleGrid
      columns={12}
      spacingX={["1rem", null, "1.5rem"]}
      spacingY={["1rem", null, "1.5rem"]}
      {...props}
    />
  )
}

export const Grid12 = ({ children, ...props }) => {
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2 }}
      gap={{ base: 4, sm: 4 }}
      {...props}
    >
      {children}
    </SimpleGrid>
  )
}

Grid12.propTypes = {
  children: PropTypes.any,
  props: PropTypes.any,
}

export const Grid123 = ({ children, ...props }) => (
  <SimpleGrid
    columns={{ base: 1, md: 2, lg: 3 }}
    pos="relative"
    gap={{ base: 1, sm: 8 }}
    py={1}
    p={{ sm: 1 }}
    {...props}
  >
    {children}
  </SimpleGrid>
)

Grid123.propTypes = {
  children: PropTypes.any,
  props: PropTypes.any,
}

export const Grid124 = ({ children, ...props }) => {
  return (
    <SimpleGrid
      columns={{ base: 1, md: 2, lg: 4 }}
      gap={{ base: 6, sm: 8 }}
      {...props}
    >
      {children}
    </SimpleGrid>
  )
}

Grid124.propTypes = {
  children: PropTypes.any,
  props: PropTypes.any,
}

export const Grid24 = ({ children, ...props }) => {
  return (
    <SimpleGrid
      columns={{ base: 2, md: 4 }}
      gap={{ base: 6, sm: 8 }}
      {...props}
    >
      {children}
    </SimpleGrid>
  )
}

Grid24.propTypes = {
  children: PropTypes.any,
  props: PropTypes.any,
}

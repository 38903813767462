import React from "react"
import PropTypes from "prop-types"
import NextLink from "next/link"
import { Link as CLink } from "@chakra-ui/react"

import { forwardRef } from "../Hooks"

export const Link = forwardRef(({ href, variant = "inline", ...rest }, ref) => {
  const props = { ref, href, ...rest }
  // render as next/link if it's a local link
  if (typeof href === "object" || href?.[0] === "/") props.as = NextLink

  return <CLink variant={variant} {...props} />
})

Link.propTypes = {
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

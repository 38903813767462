import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useRouter } from "next/router"
import Head from "next/head"

import { useTranslation } from "../../utils/i18n"
import { PageContext } from "../../utils/page"

export const SiteMeta = props => {
  const { asPath } = useRouter()
  const { t } = useTranslation()
  const { locale, menus, website } = useContext(PageContext)
  const pages = Object.values(menus || {})
    .map(p => p.og_pageLink)
    .flat()

  const url = `${website?.url}${asPath}`
  const getPage = slug => p =>
    p.entity_meta?.pageMeta?.path === slug ||
    p.og_slug[0].postgres_varchar === slug

  // used for default sharing image
  const home = pages.find(getPage("/"))
  const page = pages.find(getPage(asPath))
  let title =
    props.title || page?.entity_meta.name[0]?.i18n || t("website_title")
  title = `${title}${title && website?.name && " | "}${website?.name}`
  const description =
    props.description ||
    page?.og_description[0]?.i18n ||
    t("website_description")

  // sensible default cover image for social media
  const image = `https://datastory-images.s3.amazonaws.com/${
    props.image ||
    page?.og_image[0]?.entity_meta.local_id ||
    home?.og_image[0]?.entity_meta.local_id
  }`
  const imageWidth = props.imageWidth || 1280
  const imageHeight = props.imageHeight || 720

  return (
    <Head>
      <title>{title}</title>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      {description && (
        <>
          <meta key="description" name="description" content={description} />
          <meta
            key="og:description"
            property="og:description"
            content={description}
          />
          <meta
            key="twitter:description"
            name="twitter:description"
            content={description}
          />
        </>
      )}
      <meta key="og:locale" property="og:locale" content={locale} />
      <meta key="og:url" property="og:url" content={url} />
      <meta key="og:title" property="og:title" content={title} />
      <meta key="og:image" property="og:image" content={image} />
      <meta
        key="og:image:width"
        property="og:image:width"
        content={imageWidth}
      />
      <meta
        key="og:image:height"
        property="og:image:height"
        content={imageHeight}
      />
      <meta key="og:site_name" property="og:site_name" content="Datastory" />
      <meta key="twitter:title" name="twitter:title" content={title} />
      <meta key="twitter:image" name="twitter:image" content={image} />
      <meta
        key="twitter:card"
        name="twitter:card"
        content="summary_large_image"
      />
      <meta key="twitter:site" name="twitter:site" content="@datastory" />
    </Head>
  )
}

SiteMeta.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
}
